const ru = {
  'noscript_warning': 'Вам необходимо включить JavaScript, чтобы запустить это приложение.',
  'company_name': 'ООО «' + process.env.REACT_APP_GLOBAL_COMPANY_NAME + '»',
  'company_address': '111204, Республика Узбекистан, Ташкентская область, Кибрайский район, МСГ А.Навои, ул. А.Навои, 4',
  'attribute-photo': 'Фото',
  'attribute-username': 'Имя пользователя',
  'attribute-first_name': 'Имя',
  'attribute-last_name': 'Фамилия',
  'attribute-middle_name': 'Отчество',
  'attribute-email': 'Электронная почта',
  'attribute-date_of_birth': 'Дата рождения',
  'attribute-city': 'Город',
  'attribute-address': 'Адрес',
  'attribute-phone': 'Номер мобильного телефона',
  'attribute-role': 'Роль',
  'attribute-password': 'Пароль',
  'attribute-message': 'Cообщение',
  'attribute-thread': 'Тема',
  'attribute-subject': 'Тема',
  'attribute-content': 'Контент',
  'attribute-title': 'Название',
  'attribute-hour': 'Час',
  'attribute-minute': 'Минута',
  'attribute-second': 'Секунда',
  'attribute-name': 'Имя пользователя',
  'attribute-slug': 'Слаг',
  'attribute-description': 'Описание',
  'attribute-path': 'Путь',
  'attribute-title.uz': 'Заголовок (uz)',
  'attribute-title.ru': 'Заголовок (ru)',
  'attribute-title.en': 'Заголовок (en)',
  'attribute-slug.uz': 'Слаг (uz)',
  'attribute-slug.ru': 'Слаг (ru)',
  'attribute-slug.en': 'Слаг (en)',
  'attribute-path.*': 'Путь',
  'attribute-sorting_order': 'Порядок сортировки',
  'attribute-image': 'Изображение',
  'attribute-uploadFiles.*': 'загруженный файл',
  'attribute-locale': 'Язык',
  'attribute-publish_date': 'Дата публикации',
  'attribute-status': 'Статус',
  'attribute-template': 'Шаблон',
  'attribute-post_type': 'Тип поста',
  'attribute-published_date': 'Дата публикации',
  'attribute-page_template': 'Шаблон страницы',
  'attribute-thumbnail': 'Тамбнейл',
  'attribute-key': 'Ключ',
  'attribute-value': 'Значение',
  'attribute-key.*': 'Ключ',
  'attribute-files.*': 'Медиа файл',
  'attribute-is_public': 'Публично запрашивается на веб-интерфейсе',
  'attribute-logotype': 'Логотип',
  'attribute-avatar': 'Аватар',
  'attribute-occupation': 'Должность',

  'brand_definition': 'Онлайн бронирование услуг | YouBook.Uz',
  'ok': 'OK',
  'cancel': 'Отмена',
  'back': 'Назад',
  'create': 'Создать',
  'edit': 'Редактировать',
  'update': 'Редактировать',
  'save': 'Сохранить',
  'delete': 'Удалить',
  'select': 'Выбрать',
  'resend': 'Отправить повторно',
  'saved': 'Успешно сохранено',
  'current_password_incorrect': 'Текущий пароль неправильный',
  'password_changed': 'Пароль изменён',
  'all_rights_reserved': 'Все права защищены',
  'alternative_button_url': 'Если у вас возникли проблемы с нажатием кнопки ":action_text", скопируйте и вставьте URL-адрес ниже в свой веб-браузер',
  'user_avatar_removed': 'Ваш аватар успешно удален',
  'yes': 'Да',
  'no': 'Нет',
  'for_example': 'Например',
  'exempli_gratia': 'например',
  'optional': 'необязательное поле',
  'next': 'Следующий',
  'for': 'для',
  // ==========
  'or': 'или',
  'toggle_navigation': 'Переключить навигацию',
  'login': 'Вход',
  'register': 'Регистрация',
  'register_phone': 'Регистрация с помощью мобильного номера',
  'logout': 'Выход',
  'e_mail_address': 'Электронная почта',
  'password': 'Пароль',
  'remember_me': 'Запомнить меня',
  'forgot_your_password': 'Забыли пароль?',
  'name': 'Имя',
  'confirm_password': 'Подтвердите пароль',
  'reset_password': 'Сброс пароля',
  'send_password_reset_link': 'Отправить ссылку сбросить пароль',
  'register_with_mobile_phone_number': 'У вас нет электронной почты? {get_registered} с помощью номера мобильного телефона',
  'get_registered': 'Зарегистрируйтесь',
  'register_with_email': 'У вас нет номера мобильного телефона? {get_registered} с помощью электронной почты',
  'mobile_phone_number': 'Номер мобильного телефона',
  'username': 'Имя пользователя',
  'you_registered_successfully': 'Вы успешно зарегистрировались в системе',
  'one_id': 'Единая Система Идентификации',
  'are_you_sure': 'Вы уверены?',
  'avatar': 'Аватар',
  'my_account': 'Мой Кабинет',
  'phone': 'Номер телефона',
  'current_password': 'Текущий пароль',
  'password_change_wanted': 'Если хотите поменять пароль, введите ваш текущий пароль и новый пароль вместе с подтверждением',
  'authentication_successful': 'Вы успешно прошли аутентификацию!',
  'media_explorer': 'Медиа эксплорер',
  'upload': 'Загрузить',
  'media_list': 'Список медиа',
  'preview': 'Предварительный просмотр',
  'set': 'Установить',
  'close': 'Закрыть',
  'payload_too_large': 'Слишком большой размер файлов!',
  'add_new': 'Добавить',
  'dashboard': 'Панель',
  'personal_information': 'Личные данные',
  'generic_ajax_error': 'Что-то пошло не так! Если проблема продолжется дальше, пожалуйста, сообщите администрации.',
  'please_select_file': 'Пожалуйста выберите файл изображения для загрузки',
  'or_login_with_these': 'или зайти через',
  'pages-home': 'Главная',
  'pages-about': 'О сервисе',
  'pages-pricing': 'Тарифы',
  'pages-contact': 'Контакты',
  'pages-faq': 'Частые вопросы',
  'pages-privacy': 'Политика конфиденциальности',
  'pages-tos': 'Условия пользования',
  'pages-service-categories': 'Сферы обслуживания',
  'pages-help': 'Помощь',
  'let_us_be_social': 'Присоединяйтесь к нам',
  'we_are_on_the_networks': 'Мы в соц. сетях',
  'acceptance_of_tos': 'Пользование этим приложением означает полное согласие пользователя с {tos}.',
  'tos_agree_text': 'условиями обслуживания',
  'no_internet_connection': 'Произошла ошибка с сетью, попробуйте ещё раз',
  'you_are_not_authenticated_please_login': 'Вы не аутентифицированы, выполните вход',
  'no_sufficient_rights_to_perform_action': 'У вас недостаточно прав для выполнения этого действия',
  'errorDuringSignInWithPhoneNumber': 'Ошибка, пожалуйста, попробуйте ещё раз',
  'errorWhileCheckingTheVerificationCode': 'Ошибка при проверке кода подтверждения, пожалуйста, попробуйте ещё раз',
  'pleaseEnterTheCodeYouWillReceiveInAnSms': 'Пожалуйста, введите код, который вы получите в SMS:',
  'pleaseEnterAValidPhoneNumber': 'Пожалуйста, введите действующий номер!',
  'email_verified': 'Ваш адрес электронной почты подтвержден!',
  'verification_link_sent': 'На ваш адрес электронной почты была отправлена новая ссылка для подтверждения.',
  'verify_your_email': 'Подтверждение адреса электронной почты. Прежде чем продолжить, проверьте свою электронную почту на наличие ссылки для подтверждения. Если вы не получили письмо',
  'click_here_to_request_another': 'нажмите сюда, чтобы запросить другое',
  'start': 'Начать',

  'my_organizations': 'Мои организации',
  'title': 'Название',
  'address': 'Адрес',
  'contacts': 'Контакты',
  'add_new_organization': 'Добавить новую',
  'street_address': 'Массив, улица, дом, кв.',
  'postcode': 'Почтовый индекс',
  'email': 'Эл. почта',
  'website': 'Вебсайт',
  'telegram': 'Телеграм',
  'latitude': 'Географическая широта',
  'longitude': 'Географическая долгота',
  'location': 'Местонахождение (область, район, город)',
  'organization_title': 'Название компании, фирмы, организации, и т.п.',
  'organization_created': 'Организация создана успешно!',
  'organization_edited': 'Данные организации редактированы успешно!',
  'branches': 'Филиалы',
  'branch': 'Филиал',
  'branch_title': 'Название филиала',
  'add_new_branch': 'Добавить новый',
  'branch_created': 'Филиал успешно создан!',
  'branch_edited': 'Данные филиала редактированы успешно!',
  'set_logotype': 'Установить логотип',
  'set_logotype_success_text': 'Логотип успешно установлен!',
  'delete_logotype_success_text': 'Логотип успешно удален!',
  'settings': 'Настройки',
  'n_seconds': '{1} :value секунда|[2,4] :value секунды|[5,*] :value секунд',
  'n_minutes': '{1} :value минута|[2,4] :value минуты|[5,*] :value минут',
  'n_hours': '{1} :value час|[2,4] :value часа|[5,*] :value часов',
  'n_days': '{1} :value день|[2,4] :value дня|[5,*] :value дней',
  'n_weeks': '{1} :value неделя|[2,4] :value недели|[5,*] :value недель',
  'n_months': '{1} :value месяц|[2,4] :value месяца|[5,*] :value месяцев',
  'n_years': '{1} :value год|[2,4] :value года|[5,*] :value лет',
  'min_time_till_event': 'Минимально допустимое время до бронирования услуги',
  'max_time_till_event': 'Максимально допустимое время до бронирования услуги',
  'minimum_allowed_time_duration': 'Минимально допустимое время услуги (события)',
  'service_categories': 'Категории услуг',
  'organization_type': 'Тип организации',
  'organizational_and_legal_form': 'Организационно-правовая форма',
  'service': 'Услуга',
  'services': 'Услуги',
  'add_new_service': 'Добавить новую',
  'service_provider': 'Поставщик услуг',
  'service_providers': 'Поставщики услуг',
  'description': 'Описание',
  'display_on_booking_page': 'Показывать на странице бронирования',
  'hide_duration_on_booking_page': 'Скрыть длительность на странице бронирования',
  'duration_hours': 'Продолжительность в часах',
  'duration_minutes': 'Продолжительность в минутах',
  'buffer_hours_after': 'Запасное время в часах',
  'buffer_minutes_after': 'Запасное время в минутах',
  'regular_price': 'Обычная стоимость',
  'sale_percentage': 'Процент скидки (%)',
  'sale_price': 'Цена со скидкой',
  'service_created': 'Услуга создана успешно!',
  'service_edited': 'Данные услуги редактированы успешно!',
  'set_thumbnail': 'Установить изображение',
  'set_thumbnail_success_text': 'Изображение успешно установлено!',
  'delete_thumbnail_success_text': 'Изображение успешно удалено!',
  'full_name': 'Полное имя',
  'set_avatar': 'Установить фото',
  'set_avatar_success_text': 'Фото успешно установлено!',
  'delete_avatar_success_text': 'Фото успешно удалено!',
  'add_new_service_provider': 'Добавить новый',
  'service_provider_created': 'Поставщик услуг создан успешно!',
  'service_provider_edited': 'Данные поставщика услуг редактированы успешно!',
  'max_num_clients': 'Максимальное количество клиентов, обслуживаемых одновременно',
  'set_service_categories': 'Выбрать сферы услуг',
  'service_categories_select_warning': 'Внимание! Вы не сможете изменить свои сферы услуг после сохранения, поэтому будьте внимательны.',
  'select_provider_services': 'Выберите услуги, оказываемые этим сотрудником',
  'is_active_organization': 'Действует',
  'is_active_branch': 'Действует',
  'service_category': 'Категория услуги',
  'select_service_providers': 'Выберите обслуживающих',
  'provided_services': 'Оказываемые услуги',
  'service_provider_information': 'Информация об этом сотруднике',
  'service_information': 'Информация об этом виде услуги',
  'schedule_settings': 'График работы',
  'weekly_schedule': 'Расписание рабочей недели',
  'special_schedule': 'Расписание специальных дней',
  'unavailability_schedule': 'Недоступные периоды времен',
  'remove_schedule_interval': 'Удалить интервал',
  'monday': 'Понедельник',
  'tuesday': 'Вторник',
  'wednesday': 'Среда',
  'thursday': 'Четверг',
  'friday': 'Пятница',
  'saturday': ' Суббота',
  'sunday': 'Воскресенье',
  'monday_short': 'Пн',
  'tuesday_short': 'Вт',
  'wednesday_short': 'Ср',
  'thursday_short': 'Чт',
  'friday_short': 'Пт',
  'saturday_short': 'Сб',
  'sunday_short': 'Вс',
  'working_time': 'Рабочее время',
  'available_time': 'Доступное время',
  'non_working_time': 'Нерабочее время',
  'schedule_edited': 'График работы редактирован успешно!',
  'minor_ordinals_masculine_1': 'Первый',
  'minor_ordinals_masculine_2': 'Второй',
  'minor_ordinals_masculine_3': 'Третий',
  'minor_ordinals_masculine_4': 'Четвертый',
  'minor_ordinals_masculine_5': 'Пятый',
  'minor_ordinals_masculine_last': 'Последний',
  'minor_ordinals_feminine_1': 'Первая',
  'minor_ordinals_feminine_2': 'Вторая',
  'minor_ordinals_feminine_3': 'Третья',
  'minor_ordinals_feminine_4': 'Четвертая',
  'minor_ordinals_feminine_5': 'Пятая',
  'minor_ordinals_feminine_last': 'Последняя',
  'minor_ordinals_neutral_1': 'Первое',
  'minor_ordinals_neutral_2': 'Второе',
  'minor_ordinals_neutral_3': 'Третье',
  'minor_ordinals_neutral_4': 'Четвертое',
  'minor_ordinals_neutral_5': 'Пятое',
  'minor_ordinals_neutral_last': 'Последнее',
  'if_exists': 'если есть',
  'of_month': 'месяца',
  'select_branch': 'Выберите филиал',
  'select_date_to_set_special_schedule': 'Выберите дату, для которой вы хотите установить специальное расписание',
  'special_schedule_for': 'Специальное расписание для этой даты',
  'is_working_day': 'Рабочий день',
  'appointment': 'Назначение',
  'about_us': 'О нас',
  'staff_members': 'Сотрудники',
  'booking_steps_branch': 'Филиал',
  'booking_steps_service': 'Услуга',
  'booking_steps_provider': 'Сотрудник',
  'booking_steps_date': 'Дата',
  'booking_steps_your_info': 'О вас',
  'booking_steps_client': 'Клиент',
  'booking_steps_confirm': 'Подтвердить',
  'booking_steps_client_info': 'О клиенте',
  'booking_steps_place': 'Место',
  'booking_steps_inventory': 'Инвентарь',
  'organization_description': 'Описание организации, информация об этой организации',
  'branch_description': 'Описание филиала, информация об этом филиале',
  'until_you_select_special_time_and_press_save_button': 'Пока вы не нажмете кнопку "Сохранить" после выбора специальной даты и установки специального расписания для нее, никакие изменения не сохраняются. Расписание специальных дат имеет больший приоритет, чем другие дни.',
  'select_service': 'Выберите услугу',
  'you_can_select_multiple_services': 'Можете выбрать несколько видов услуг',
  'shortened_time_labels_h': 'ч.',
  'shortened_time_labels_m': 'м.',
  'shortened_time_labels_s': 'с.',
  'cost': 'Стоимость',
  'duration': 'Продолжительность',
  'free_of_charge': 'Бесплатно',
  'unknown': 'Неизвестно',
  'select_service_provider': 'Выберите сотрудника',
  'select_datetime': 'Выберите дату и время',
  'first_name': 'Имя',
  'last_name': 'Фамилия',
  'middle_name': 'Отчество',
  'mandatory_fields': 'Поля, обязательные для заполнения',
  'client': 'Клиент',
  'confirm_booking_details': 'Подтвердить данные',
  'date': 'Дата',
  'starts_at': 'Время начала',
  'confirm_booking': 'Подтвердить',
  'add_another_service': 'Добавить услугу',
  'branch_schedule_may_differ_from_organization': 'График работы филиала может отличаться от графика головного офиса',
  'provider_services': 'Услуги, оказываемые этим сотрудником',
  'higher_priority_than_4_th_or_5_th_day': 'Приоритетнее чем 4ый или 5ый дни недели месяца',
  'select_another_date_and_time': 'Дата и время, которые вы выбрали, пересекаются со временем уже выбранных услуг. Пожалуйста, выберите другую дату и время.',
  'select_other_available_date_and_time': 'Дата и время, которые вы выбрали, пересекаются с нерабочим временем. Пожалуйста, выберите другую дату и время.',
  'unavailable_date_and_time': 'Дата и время, которые вы выбрали, недоступны. Пожалуйста, выберите другую дату и время.',
  'select_other_date_and_time_slots': 'Дата и время, которые вы выбрали, пересекаются с нерабочим временем. Пожалуйста, выберите другую дату и время.',
  'special_schedule_applies_to_service_providers': 'Специальный график применяется и к сотрудникам этого филиала.',
  'working_time_in_other_branches': 'Рабочее время в других филиалах',
  'authorize_suggest_prompt_text': 'Зарегистрируйтесь или зайдите в личный кабинет чтобы не заполнять поля каждый раз',
  'service_provider_not_available': 'Поставщик услуг не доступен на выбранное время, похоже, что кто-то уже забронировал. Пожалуйста, выберите другое время или поставщика услуг',
  'select_at_least_one_service_to_book': 'Пожалуйста, выберите хотя бы одну услугу для бронирования',
  'occupied': 'занят(а)',
  'working_time_slot': 'рабочее время',
  'partly_available_service_provider_time_slot': 'частично доступный временной интервал (поставщик услуг может принимать по тому же виду сервиса)',
  'non_working_or_booked_time_slot': 'нерабочий или забронированный временной интервал',
  'select_other_slot_or_the_beginning_of_booked_time_slot': 'Дата и время, которые вы выбрали, пересекаются с уже забронированным временем. Пожалуйста, выберите другую дату и время или начало забронированного временного интервала для той же услуги.',
  'select_other_slot': 'Дата и время, которые вы выбрали, пересекаются с уже забронированным временем. Пожалуйста, выберите другую дату и время.',
  'my_bookings_and_appointments': 'Назначения и встречи',
  'my_restaurant_bookings': 'Бронирование мест',
  'my_rentals': 'Арендование',
  'my_events_and_entertainment': 'События и мероприятия',
  'booking_accepted': 'Принято!',
  'your_booking_accepted_text': 'Ваш запрос принят. Если у вас есть какие-либо вопросы или комментарии, вы можете связаться с администрацией организации через контактную информацию. Спасибо!',
  'to_continue_press_close_button': 'Чтобы продолжить нажмите кнопку закрыть.',
  'select_your_organization': 'Выберите свою организацию',
  'code': 'Код',
  'booking_code': 'Код бронирования',
  'created_at': 'Дата создания',
  'status': 'Статус',
  'actions': 'Действия',
  'active': 'Активный',
  'inactive': 'Неактивный',
  'new': 'Новый',
  'pending': 'В ожидании',
  'approved': 'Одобрено',
  'cancelled': 'Отменено',
  'expired': 'Истекло',
  'view': 'посмотреть',
  'show_filters': 'Показать фильтры',
  'hide_filters': 'Скрыть фильтры',
  'apply': 'Применить',
  'clear': 'Очистить',
  'created_at_from': 'Дата создания с',
  'created_at_to': 'Дата создания до',
  'service_at_from': 'Время услуги с',
  'service_at_to': 'Время услуги до',
  'please_wait': 'Подождите',
  'bookings_and_appointments': 'Назначения и встречи',
  'select_please': 'Пожалуйста, выберите',
  'booking_types_select_appointments': 'Назначения и встречи',
  'booking_types_select_services': 'Назначения и встречи',
  'booking_types_select_rentals': 'Арендование',
  'booking_types_select_restaurants': 'Бронирование мест',
  'booking_types_select_reservations': 'Бронирование мест',
  'booking_types_select_events': 'Бронирование для мероприятий',
  'booking_channels_for_clients': 'Способы бронирований или записей на прием для клиентов',
  'booking_channels_by_phone': 'По телефону',
  'booking_channels_on_shopfront': 'На веб-странице организации',
  'bookings_performed_hints_by_phone': 'Принимается администратором по телефону, этот метод всегда доступен',
  'bookings_performed_hints_on_shopfront': 'Осуществляется самостоятельно клиентами на веб-странице организации',
  'notification_channels_for_clients': 'Способы отправки уведомлений клиентам',
  'notification_channels_by_email': 'По электронной почте',
  'notification_channels_by_telegram': 'По Telegram',
  'notification_channels_by_sms': 'По СМС',
  'notifications_performed_hints_by_email': 'Сообщение будет отправлено на адрес электронной почты клиента (если он есть), этот способ всегда включен',
  'notifications_performed_hints_by_telegram': 'Сообщение отправляется на Telegram-аккаунт клиента (если есть), этот способ всегда включен',
  'notifications_performed_hints_by_sms': 'Сообщение отправляется на номер мобильного телефона клиента, этот способ можно включить или выключить',
  'make_a_call': 'позвонить',
  'not_accepted_on_shopfront_page': 'Эта организация не принимает бронирования, запросы на встречи и на аренду с веб-страницы организации. Вы можете связаться с администрацией наиболее удобными для вас способами. Благодарим!',
  'you_can_use_the_following_contact_details': 'Вы можете пользоваться следующими контактными данными',
  'my_administrators': 'Администраторы',
  'my_administrator_deleted': 'Администратор удален',
  'administrator_created_successfully': 'Администратор успешно создан',
  'error_while_creating_administrator': 'Произошла ошибка при создании администратора',
  'organizations_or_branches': 'Организации (филиалы)',
  'set_administrator_branches': 'Укажите свои организации (филиалы) которых будет обслуживать этот оператор',
  'search_organization': 'Поиск организации',
  'go_to_organization_page': 'Перейти',
  'nothing_under_this_service_category': 'В категории услуг \':service_category\' не найдено организаций',
  'try_searching_in_other_categories': 'Пожалуйста, попробуйте поискать в <a href=\':categories_url\'>других категориях</a>',
  'doesnt_supply_services_yet': 'Эта организация пока не оказывает услуги',
  'try_searching_for_other_organizations': 'Попробуйте поискать другие',
  'for_other_organizations': 'другие организации',
  'no_organization_found_in_search': 'По вашему запросу \':search_token\' ничего не найдено',
  'found_organizations_for_your_search': 'Результаты вашего поискового запроса \':search_token\'',
  'organization_search_hint': 'Название организации, город или район, адрес',
  'whereabouts': 'Местонахождение',
  'search': 'поиск',
  'no_organizations_found': 'Организаций не найдено',
  'minute': 'м.',
  'calendar': 'Календарь',
  'list': 'Список',
  'details': 'Информация',
  'passphrase': 'Кодовая фраза',
  'approve_booking': 'Одобрить',
  'cancel_booking': 'Отменить',

  'rentals': 'Аренда',
  'rental_services': 'Услуги аренды',
  'inventory': 'Инвентарь',
  'table_place_booking': 'Бронирование столов, мест',
  'tables_rooms': 'Столы, номера, комнаты',
  'events_and_entertainment': 'События и мероприятия',
  'tickets_seats_rooms': 'Билеты, места, номера, комнаты',
  'serves_which_branches': 'Обслуживаемые филиалы',
  'occupation': 'Должность',
  'make_an_appointment': 'Назначить встречу/приём',
  'rent': 'Арендовать',
  'make_a_reservation': 'Забронировать стол/место',
  'sign_up_for_event': 'Записать на мероприятие',
  'reservables': 'Столы, номера, комнаты',
  'short_information': 'Краткая информация',
  'any_short_information': 'Любая краткая информация',
  'reservable_type_table': 'Стол',
  'reservable_type_room': 'Номер/комната',
  'title_or_number': 'Название или номер (№)',
  'select_what_to_create': 'Выберите, что создать',
  'please_select_an_image_file': 'Пожалуйста выберите изображение!',
  'room_type': 'Тип помещения',
  'room_type_room': 'Комната',
  'room_type_hall': 'Зал',
  'room_type_balcony': 'Балкон',
  'rentables': 'Сдаваемые в аренду',
  'sku': 'Артикул (SKU)',
  'inventory_availability_determined_by_sku': 'Доступность инвентарей с одним и тем же названием определяется артикулом (SKU)',
  'service_type_service': 'Услуга',
  'service_type_appointment': 'Встреча/Приём',
  'service_type_training': 'Тренировка/Занятие',
  'service_type_tutoring': 'Репетиторство',
  'service_type_other_service': 'Другой вид услуги',
  'type_of_tariffication': 'Вид тарификации',
  'tariffication_type_hourly': 'Почасовая',
  'tariffication_type_daily': 'Посуточная',
  'tariffication_type_weekly': 'Понедельная',
  'tariffication_type_monthly': 'Помесячная',
  'rentable_type_book': 'Книга',
  'rentable_type_real_estate': 'Недвижимость',
  'rentable_type_room': 'Номер/Комната',
  'rentable_type_heavy_machinery': 'Тяжелая техника',
  'rentable_type_construction_equipment': 'Строительное оборудование',
  'rentable_type_car': 'Машина',
  'rentable_type_bicycle': 'Велосипед',
  'rentable_type_sport_equipment': 'Спортивный инвентарь',
  'rentable_type_disk': 'Диск',
  'rentable_type_other_inventory': 'Другой инвентарь',
  'events': 'Мероприятия',
  'event_name': 'Название мероприятия или события',
  'event_information': 'Информация об этом мероприятии',
  'stock_count': 'Количество',
  'event_datetime_from': 'Дата и время события с',
  'event_datetime_to': 'Дата и время события по',
  'select_branches_where_this_service_provided': 'Выберите филиал(ы), где предоставляется эта услуга',
  'select_branch_where_this_inventory_belongs_to': 'Выберите филиал, к которому принадлежит этот инвентарь',
  'venue_arrangement': 'Подготовка мест проведения',
  'venues': 'Места проведений',
  'create_one_by_one': 'Создавать по одному',
  'generate_in_bulk': 'Сгенерировать массово',
  'generate': 'Сгенерировать',
  'venue_type_ticket': 'Билет',
  'venue_type_seat': 'Место',
  'venue_type_room': 'Номер/Помещение',
  'venue_number_or_title': 'Номер (№) или название',
  'row_number': 'Номер ряда',
  'seat_number': 'Номер места',
  'row': 'Ряд',
  'seat': 'Место',
  'page_not_found': 'Страница, которую вы искали, не найдена',
  'places': 'Места',
  'subtype': 'Предмет',
  'schedule': 'График работы',
  'lasting': 'Длительность',
  'category': 'Категория',
  'client_info': 'Данные клиента',
  'select_place': 'Выберите место',
  'indicate_how_long_will_stay': 'Пожалуйста, укажите, как долго вы будете оставаться (продолжительность в минутах)',
  'hours': 'ч.',
  'max_stay_duration_is_until_workday_end': 'максимальная продолжительность пребывания может быть до конца рабочего дня',
  'place': 'Место',
  'time': 'Время',
  'select_inventory': 'Выберите инвентарь',
  'you_can_rent_only_on_one_tariffication_basis': 'Одновременно вы можете арендовать только по одному типу тарификации',
  'indicate_how_long_will_rent': 'Пожалуйста, укажите, на какой срок будете арендовать',
  'renting_duration_hourly': 'продолжительность в часах',
  'renting_duration_daily': 'продолжительность в днях',
  'renting_duration_weekly': 'продолжительность в неделях',
  'renting_duration_monthly': 'продолжительность в месяцах',
  'renting_period_from': 'Срок аренды с',
  'renting_period_to': 'Срок аренды по',
  'bookings': 'Бронирования',
  'view_type': 'Вид',
  'datetime_from': 'Время с',
  'datetime_to': 'Время по',
  'service_s': 'Услуга(и)',
  'bookables_total': 'Итого',
  'text_copied': 'Текст скопирован!',
  'cost_depends_on_order': 'Цена зависит от заказа',
  'schedule_was_not_set': 'График работы не установлен',
  'service_datetime_from': 'Время услуги с',
  'service_datetime_to': 'Время услуги по',
  'rental_return_time_is_non_working': 'Время возврата инвентаря нерабочее, пожалуйста, либо выберите другое время, либо свяжитесь с персоналом организации.',
  'telegram_username': 'Имя пользователя в телеграме (без знака @)',
  'telegram_username_hint': 'на этот аккаунт можно получать уведомления если дать доступ нашему <a target="_blank" href="' + process.env.REACT_APP_TELEGRAM_BOT_URL + '">боту</a>, для этого нужно всего лишь набрать команду <a target="_blank" href="' + process.env.REACT_APP_TELEGRAM_BOT_DESCRIPTION_URL + '">/link</a> в боте',
  'booking_information': 'Детали бронирования',
  'client_notes': 'Комментарии клиента',
  'admin_notes': 'Комментарии оператора',
  'cancellation_reason': 'Причина отмены',
  'notes': 'Заметки',
  'dont_forget_to_leave_notes': 'Если применимо укажите причину отмены в разделе "Заметки".',
  'inputs_are_saved_automatically': 'введенные значения сохраняются автоматически',
  'client_preferred_language': 'Предпочитаемый язык общения',
  'notifications': 'Уведомления',
  'message': 'Сообщение',
  'read_at': 'Прочитано',
  'you_dont_have_notifications_yet': 'У вас пока нету уведомлений',
  'got_impressed_to_our_features': 'Вам понравились возможности нашего приложения?',
  'got_impressed_get_register': 'Тогда не теряйте время и начните свой бизнес бронирований вместе с нами прямо сейчас',
  'get_to_know': 'Ознакомиться',
  'get_started_now': 'Начните сейчас',
  'how_it_works': 'Как это работает',
  'all_service_categories': 'Посмотреть все',
  'our_service_categories': 'Наши сферы обслуживания',
  'choose_service_category_fitting_your_business': 'Выбирайте категорию услуг, которая подходит для вашего бизнеса',
  'forbidden': 'Запрещено',
  'go_back_home': 'Перейти на Главную',
  'make_a_booking': 'Бронировать',
  'faq': 'Часто задаваемые вопросы',
  'notifications_will_be_sent': 'уведомления будут отправляться',
  'timeslots_shifting_and_time_gaps_in_between_warning': 'Старайтесь выбрать временной интервал как можно ближе к последнему бронированию или к ближайшему рабочему часу, если это уместно. Не пытаться избегать возможных временных промежутков которых невозможно бронировать может привести к тому, что администраторы организации отменят ваше бронирование.',
  'help_center': 'Центр поддержки',
  'enter_some_keywords': 'Введите ключевые слова',
  'nothing_found': 'Ничего не найдено',
  'view_booking_details': 'Посмотреть детали можно по этой ссылке',
  'can_you_return_inventory_until_the_end_of_the_working_hours': 'Точное время возврата инвентаря является нерабочим, к сожалению. Сможете ли вы вернуть инвентарь до окончания рабочего дня? Скажем, за 5 минут?',
  'attention': 'Внимание!',
  'place_type': 'Тип места',
  'max_time_till_event_text': 'Максимально допустимое время до бронирования услуги -',
  'too_many_requests': 'Вы отправили слишком много запросов, пожалуйста, повторите попытку чуть позже!',
  'balance': 'Баланс',
  'did_not_find_what_you_were_looking_for': 'Не нашли что искали?',
  'there_is_an_update': 'Доступна новая версия приложения!',
  'refresh-now': 'Обновить',
  'not-now': 'Не сейчас',
  'we_use_cookies_notification': 'Мы используем файлы cookie для обеспечения работоспособности и улучшения качества обслуживания.',
  'i_agree': 'Соглашаюсь',
  'menu': 'Меню',
  'paid_sms_service_warning': 'услуга смс платная, единая цена за каждую смс в размере ' + process.env.REACT_APP_SMS_COST + ' сум, sms-сообщения рассылаются клиентам при оформлении, подтверждении, отмене бронирования, а также при изменении графика работы организации, филиала и обслуживающего персонала',
  'go_to_booking_page': 'перейти на страницу бронирования',
  'reset_password_with_email': 'Восстановить пароль с помощью эл. почты',
  'reset_password_with_otp': 'Восстановить пароль с помощью кода',
  'confirm_mobile_number': 'Подтвердить мобильный номер',
  'email_must_be_verified_to_get_notifications': 'Ваш адрес электронной почты должен быть подтвержден, чтобы вы могли получать уведомления, включая уведомление о сбросе пароля, если вы его забудете.',
  'add_email_or_telegram_account_warning': 'У вас не добавлены эл. почта и Telegram-аккаунт. Пожалуйста, рассмотрите возможность добавления обеих или любого из них, чтобы иметь возможность получать от нас уведомления и иметь возможность восстанавливать свой пароль, если вы его забудете.',
  'telegram_not_linked_warning': 'эта учетная запись telegram не подключена к системе, для подключения необходимо зайти в наш <a target="_blank" href="' + process.env.REACT_APP_TELEGRAM_BOT_URL + '">бот</a> и ввести команду <span class="text-info">/link</span>',
  'otp': 'Код подтверждения',
  'send_otp': 'Отправить код подтверждения',
  'enter_telegram_otp': 'Введите код, который вы получите в своем аккаунте Telegram',
  'invoices': 'Счета-фактуры',
  'invoices_do_not_exist': 'Счета-фактуры еще не существуют',
  'you_have_due_invoices': 'Имеются счета-фактуры на оплату',
  'download': 'скачать',
  'paid': 'оплачено',
  'unpaid': 'неоплачено',
  'no_invoices_yet': 'Счета-фактур пока нет',
  'invoices_created_automatically_hint': 'Счета-фактуры создаются автоматически и подлежат оплате в начале каждого последующего календарного месяца',
  'contact_us_in_convenient_way': 'Можете связаться с нами любым удобным для вас способом:',
  'contact_via_telegram': 'Отправьте свое сообщение через Telegram в любое время!',
  'contact_via_email': 'Также можете писать на нашу эл. почту!',
  'contact_via_phone': 'Звоните по поводу сотрудничества!',
  'looking_for_a_guide': 'Ищете руководство по использованию приложения?',
  'install-now': 'Установить',
  'understand': 'Понятно',
  'get_to_know_in_detail': 'Узнать подробнее',
  'this_pwa_can_be_installed': 'Установите это веб-приложение на свое устройство, разместив ярлык приложения на рабочем столе, который впоследствии позволит вам получить доступ к нему одним щелчком',
  'unhandled_error_hint': 'Что-то пошло не так! Попробуйте перезагрузить страницу, если проблема сохранится, пожалуйста, сообщите администрации. Можете также отправить изображение (screenshot) страницы',
  'reload': 'Перезагрузить',
  'organization_telegram_username_hint': 'username пользователя, группы или канала',
  'enter_sms_otp': 'Введите код, который вы получите в SMS',
  'get_to_know_yet_more': 'Узнать подробнее',
  'user_privacy_warning_for_representative': 'В соответствии с {privacy_policy} необходимо получить разрешение на обработку персональных данных у этого {user_type}. За это отвечает представитель организации.',
  'user_type_administrator': 'администратора',
  'user_type_service_provider': 'поставщика услуг',
  'delete_account_text': 'Удаление аккаунта',
  'delete_account_hint': 'Удаление вашего аккаунта провоцирует удаление всей информации касающейся вашего аккаунта, включая ваши организации и всю надлежащую к ними информацию, раннее совершённые бронирования и т.д. без возможности восстановления.',
  'delete_my_account_text': 'Удалить мой аккаунт',
  'enter_password_to_confirm_identity': 'Введите пароль, чтобы подтвердить свою личность',
  'yes_absolutely': 'Да, точно',
  'changed_my_mind': 'Нет, я передумал(а)',
  'all_account_info_shall_be_deleted': 'Вы уверены? Вся информация вашей учетной записи будет удалена, и вы не сможете впоследствии её восстановить!',
  'contact_via_phone_work_hours': 'Пон. - Пят., 09:00 - 18:00',
  'who_made_the_booking': 'Кто совершил',
  'the_client_themselves': 'Клиент самостоятельно',
  'is_deleted': 'удалён',
  'service_type': 'Вид услуги',
  'information': 'Описание',
  'price': 'Стоимость',
  'decides_whether_to_show_to_clients': 'Решает отображать клиентам или нет',
  'mark_as_read': 'Прочитано',
  'client_acceptance_of_tos': 'Предоставляя личные данные клиент выражает свое полное согласие с {tos}.',
  'invoice_total_abs': 'Итого',
  'invoice_total_bookings': 'Итого брон.',
  'invoice_total_smses': 'Итого SMS',
  'select_served_branches': 'Выберите филиалы, обслуживаемые этим сотрудником',
  'etc': 'И другие',
  'get_current_coordinates_short_text': 'получить координаты',
  'get_current_coordinates': 'получить координаты на основе текущего местоположения',
  'welcome': 'Добро пожаловать',
  'i_am_a_service_provider': 'Я являюсь поставщиком услуг',
  'i_am_a_customer': 'Я являюсь клиентом',
  'get_help': 'Помощь',
  'not_applicable': 'Отсутствует',
  'enable_push_notifications': 'Подписаться на push-уведомления',
  'notifications_settings': 'Уведомления',
  'browser_does_not_support_notifications': 'Этот браузер не поддерживает уведомления.',
  'you_have_denied_push_notifications_permission': 'Вы отклоняли разрешение на push-уведомления. Пожалуйста, предоставьте разрешение на подписку.',
  'you_may_have_to_reinstall_app': 'Возможно, вам придется переустановить приложение, чтобы снова предоставить разрешение.',
  'install_home_screen_for_push_notifications': 'Для подписки и получения push-уведомлений на iOS необходимо добавить веб-приложение на главный экран.',
};

export default ru;
