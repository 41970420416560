import messages from './lang/messages';
import {
  helperCurrentLang,
  helperShowProgressSpinner,
  helperHideProgressSpinner,
} from './helpers/index';

export function register() {
  const lang = helperCurrentLang();

  if ('serviceWorker' in window.navigator) {
    window.addEventListener('load', function () {
      window
        .navigator
        .serviceWorker
        .register('/serviceworker.js')
        .then((registration) => {
          console.log('sw registered');

          registration.onupdatefound = function () {
            console.log('an update found for sw');
            const installingWorker = registration.installing;
            if (installingWorker == null) {
              return;
            }
            console.log('new sw is installing...');
            installingWorker.onstatechange = function () {
              if (installingWorker.state === 'installed') {
                if (window.navigator.serviceWorker.controller) {
                  window.alertModal(messages[lang]['there_is_an_update'], 'reload-modal');
                  console.log(messages[lang]['there_is_an_update']);
                } else {
                  console.log('content is cached for offline use');
                }
              }
            };
          };
        })
        .catch((error) => {
          console.error('error registering sw', error);
        });

      window
        .document
        .addEventListener('codebase_updated', (evt) => {
          window
            .navigator
            .serviceWorker
            .ready
            .then((registration) => {
              if (registration) {
                console.log('updating sw...');
                registration.update();
              }
            });
        });

      const previousServiceWorker = window.navigator.serviceWorker.controller;
      window
        .navigator
        .serviceWorker
        .addEventListener('controllerchange', function () {
          if (previousServiceWorker) {
            // This is due to an update, reload the page so that new sw takes control.
            window.location.reload();
          } else {
            // This is due to a SW taking control for the first time, no need to reload the page.
          }
        });
    });
  }
}

export function unregister() {
  if ('serviceWorker' in window.navigator) {
    window
      .navigator
      .serviceWorker
      .ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

export function reloadAfterUpdate() {
  if ('serviceWorker' in window.navigator) {
    window
      .navigator
      .serviceWorker
      .ready
      .then((registration) => {
        if (registration.waiting && registration.waiting.state === 'installed') {
          registration.waiting.postMessage({
            type: 'skip_waiting',
          });
        }
      });
  }
}

export function subscribeToPushNotifications(successCallback) {
  if ('serviceWorker' in window.navigator) {
    window
      .navigator
      .serviceWorker
      .ready
      .then((registration) => {
        helperShowProgressSpinner();
        console.log('subscribing to push notifications...');
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
        };
        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then(function (pushSubscription) {
        console.log('subscribed to push notifications');
        updatePushSubscriptionOnServer(JSON.stringify(pushSubscription), successCallback);
        helperHideProgressSpinner();
      })
      .catch(function (error) {
        window.alertModal(error);
        console.error('an error occured while subscribing', error);
        helperHideProgressSpinner();
      });
  }
}

export function unsubscribeFromPushNotifications(successCallback) {
  if ('serviceWorker' in window.navigator) {
    window
      .navigator
      .serviceWorker
      .ready
      .then((registration) => {
        registration.pushManager.getSubscription()
          .then(function (pushSubscription) {
            if (pushSubscription) {
              console.log('unsubscribing from push notifications...');
              deletePushSubscriptionOnServer(pushSubscription.endpoint, () => {
                successCallback();
                if (pushSubscription.unsubscribe()) {
                  console.log('unsubscribed successfully');
                } else {
                  console.error('an error occured while unsubscribing');
                }
              });
            } else {
              console.error('no subscription info found from the vendor');
            }
          });
      });
  }
}

function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    // eslint-disable-next-line
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function updatePushSubscriptionOnServer(pushSubscription, successCallback) {
  window.axios
    .post('update-web-push-subscription', {
      'pushSubscription': pushSubscription,
    })
    .then((response) => {
      console.log('push subscription saved');
      successCallback();
    })
    .catch((error) => {
      console.error('error saving push subscription');
    });
}

function deletePushSubscriptionOnServer(endpoint, successCallback) {
  window.axios
    .post('delete-web-push-subscription', {
      'endpoint': endpoint,
    })
    .then((response) => {
      console.log('push subscription deleted');
      successCallback();
    })
    .catch((error) => {
      console.error('error deleting push subscription');
    });
}

export function isSubscribedToPushNotifications(successCallback) {
  if ('serviceWorker' in window.navigator) {
    window
      .navigator
      .serviceWorker
      .ready
      .then((registration) => {
        registration.pushManager.getSubscription()
          .then(function (pushSubscription) {
            if (pushSubscription) {
              window.axios
                .post('owns-web-push-subscription', {
                  'endpoint': pushSubscription.endpoint,
                })
                .then((response) => {
                  if (response.data.result) {
                    successCallback();
                  }
                })
                .catch((error) => {
                  console.error('server error, could not determine the subscription status');
                });
            } else {
              console.log('no subscription info found from the vendor');
            }
          });
      });
  }
}
