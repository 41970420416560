export function setFormErrors(payload) {
  return {
    type: 'SET_FORM_ERRORS',
    payload: payload,
  };
}

export function setLang(lang) {
  window.localStorage.setItem('user-lang', lang);
  return {
    type: 'SET_LANG',
    payload: {
      lang: lang,
    },
  };
}

export function fetchLocations() {
  return (dispatch, getState) => {
    return window.axios
      .get('/locations', {
        params: {
          locale: getState().lang,
        },
      })
      .then((result) => {
        dispatch({
          type: 'SET_LOCATIONS',
          payload: {
            locations: result.data.locations,
          },
        });
      });
  };
}

export function setPageTitle(pageTitle, hidePageTitle) {
  return {
    type: 'SET_PAGE_TITLE',
    payload: {
      pageTitle: pageTitle,
      hidePageTitle: hidePageTitle,
    },
  };
}

export function setIsPwaInstalled(flag) {
  return {
    type: 'SET_IS_PWA_INSTALLED',
    payload: {
      flag: flag,
    },
  };
}

export function setMediaBreakpoint(mediaBreakpoint) {
  return {
    type: 'SET_MEDIA_BREAKPOINT',
    payload: {
      mediaBreakpoint: mediaBreakpoint,
    },
  };
}
